import Vue from 'vue'

const initState = () => ({
  cartItemCount: 0,
  cartItems: [],
  salesTax: null,
  shippingPrice: 0,
  customerAddresses: {
    shippingAddress: {},
    billingAddress: {},
    useDifferentShippingAddress: false,
  },
})

export const actions = {
  async addToCart({commit}, {shoppingCartItem}) {
    let shoppingCartId = Vue.prototype.$cookie.get('shoppingCartId')
    if (!shoppingCartId && !this.$auth.loggedIn) {
      shoppingCartId = Vue.prototype.$uuid.v4()
      Vue.prototype.$cookie.set('shoppingCartId', shoppingCartId, {expires: 7})
    }
    const addToCartResult = await this.$axios.$post(
      '/shoppingCart/frontendAddToCart?ShoppingCartSessionId=' + shoppingCartId
    )

    if (addToCartResult.hasErrors) {
      this.$toast.error(
        'Something went wrong please contact the site administrator!'
      )
    }
  },
}
